/*!
  GRID
  ===============================================
  */
.rw
  display: flex
  flex-direction: row
  flex-wrap: wrap
  list-style: none
  margin: 0 (-($gridPadding))


.cl
  width: 100%
  padding: 0 $gridPadding


grid(24)

@media note
  grid(24, '-nt')

@media laptop
  grid(24, '-lp')

@media tablet
  grid(24, '-tb')

@media pad
  grid(24, '-pd')

@media phone
  grid(24, '-ph')


/*!
  UI
  ===============================================
  */

body
  font-family: $fontCreata
  color: $colorText


.container
  width: 100%
  max-width: $container
  padding: 0 $containerPadding
  margin: 0 auto


.link
  transit()

  &:hover
    opacity: 0.6


.input
  display: block
  width: 100%


.hide
  display: none


.img
  display: block
  max-width: 100%


picture
  display: block

  img
    display: block
    width: 100%


.picture-cover
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0

  img
    width: 100%
    height: 100%
    object-fit: cover


.tab
  display: none
  visibility: hidden
  opacity: 0

  &.is-active
    display: block
    visibility: visible
    animation: tab-show 1s linear 0.01s 1 forwards

@keyframes tab-show
  from
    opacity: 0
  to
    opacity: 1


.iframe-container
  overflow: hidden
  padding-top: 56.25%
  position: relative
  width: 100%

  iframe
    border: 0
    height: 100%
    left: 0
    position: absolute
    top: 0
    width: 100%


.icon, .ico
  display: inline-block


/*!
  TEXT
  ===============================================
*/
.ui-h1
  font-size 48px
  font-weight: bold
  font-style: italic
  line-height: floor(52/48,2)
  @media pad
    font-size: 38px
  @media phone
    font-size: 32px


.ui-h2
  font-size 32px
  font-weight: bold
  font-style: italic
  line-height: floor(36/32,2)


.ui-h3
  font-size 20px
  font-weight: bold
  font-style: italic
  line-height: floor(24/20,2)


.ui-h4
  font-size 16px
  font-weight: bold
  font-style: italic
  line-height: floor(20/16,2)


.ui-txt
  font-size 20px
  line-height: floor(26/20,2)
  font-style: normal
  font-weight: normal


.ui-txt-small
  font-size 16px
  font-style: normal
  font-weight: bold
  line-height: floor(18/16,2)


.ui-content
  p:not(:last-child)
    margin-bottom: 12px


.ui-link
  &:hover
    color: $colorHover

  &:active
    color: $colorActive



/*!
  BUTTON
  ===============================================
*/
.ui-btn
  height: 36px
  display: inline-flex
  align-items: center
  justify-content: center
  text-align: center
  font-size: 14px
  font-weight: 500
  color: #fff
  background-color: $colorAccent
  padding: 0 12px

  &:hover
    background-color: $colorHover

  &--red
    background-color: #F53D3D

    &:hover
      background-color: #FF6666


/*!
  ELEMENTS
  ===============================================
*/
.ui-page
  padding: 44px 0 0


.page-title
  text-align: center
  text-transform: uppercase
  margin-bottom: 60px
  @media pad
    margin-bottom: 44px


.page-layout
  @media pad
    flex-direction: column-reverse

  &__left
    padding-left: 40px


.cat-sidebar
  padding-top: 20px
  @media pad
    display: flex
    justify-content: space-between
  @media phone
    flex-direction: column

  &:not(:last-child)
    margin-bottom: 52px

  &__title
    text-transform: uppercase
    margin-bottom: 14px

    span
      display inline-block
      padding-bottom: 1em
      border-bottom: 2px solid #0B5180

  &__item
    &:not(:last-child)
      margin-bottom: 52px


.cat-list
  color: #0B5180

  &__item
    position: relative

    &:before
      pseudo()
      width: 6px
      height: 100%
      bg(0 70%, 100% auto)
      background-image: url('../icons/chevron-nav.svg')
      right: 100%
      margin-right: 10px
      display: none

    &.is-active
      color: #000

      &:before
        display: block

    &:not(:last-child)
      margin-bottom: 16px

  &__link
    &:hover
      color: $colorHover
    &:active
      color: $colorActive

  &__sub[class]
    margin-top: 18px
    margin-left: 28px


.b-callback
  &__icon
    size(90px,76px)
    bg(center,contain)
    background-image: url('../icons/dialog.svg')
    margin-bottom: 16px
    margin-left: -10px

  &__title
    text-transform: uppercase
    margin-bottom: 24px

  &__btn
    margin-top: 14px


.b-grid
  &__item
    padding-bottom: 12px



.card-product
  display: block
  padding: 28px

  &__picture
    max-width: 252px
    max-height: 252px
    margin: 0 auto 24px
    @media phone
      height: 200px

    img
      size(100%,100%)
      object-fit: cover

  &__title
    text-align: center
    text-transform: uppercase
