/*!
  HEADER
  ===============================================
  */
.header
  position: relative
  box-shadow: 0px 4px 4px rgba(11, 81, 128, 0.1)
  padding: 12px 0
  @media pad
    height: 40px
    z-index: 10

  .h-logo
    position: absolute
    top: 0
    left: 50%
    height: 100%
    transform: translateX(-50%)
    width: 100%
    max-width: 150px
    display: flex
    align-items: center
    justify-content: center
    @media tablet
      max-width: 100px

  .burger
    position: absolute
    top: 50%
    margin-top: -12px
    left: 20px
    display: none
    @media pad
      display: block

  &.is-active .h-box
    display: block
    visibility: visible
    animation: tab-show 0.25s linear 0.01s 1 forwards


.h-box
  display: flex
  align-items: center
  justify-content: space-between
  @media pad
    position: fixed
    top: 40px
    left: 0
    bottom: 0
    width: 100%
    background-color: #fff
    z-index: 5
    display: block
    padding-top: 48px
    box-shadow: inset 0px 4px 4px rgba(11, 81, 128, 0.1)
    display: none
    visibility: hidden
    opacity: 0

  &__left
    @media pad
      margin-bottom: 72px


.h-logo
  display: block
  width: 100%
  max-width: 150px
  @media tablet
    max-width: 100px


.h-menu
  display: flex
  align-items: center
  color: #000
  font-size 14px
  font-weight: 500
  text-transform: uppercase
  @media pad
    flex-direction: column
    width: 100%
    font-size: 20px
    font-weight: normal
    text-transform: none


  &__item
    &:not(:last-child)
      margin-right: 8px
      @media tablet
        margin-right: 0
      @media pad
        margin-bottom: 16px

  &__link
    display: inline-flex
    align-items: center
    justify-content: center
    height: 36px
    padding: 0 12px

    &:hover
      color: $colorHover

    &:active
      color: $colorActive

    &.is-current
      color: #fff
      background-color: $colorAccent
      @media pad
        background: none
        color: $colorAccent


.h-contact
  display: inline-flex
  align-items: center
  @media pad
    display: block
    text-align: center

  &__phone
    font-size 14px
    color: #000
    font-weight: 500
    padding: 0 20px
    @media pad
      font-size 20px
      font-weight: normal
      margin-bottom: 12px

  &__btn
    .ui-btn
      @media pad
        background-color: $colorAccent


.burger
  display: block
  size(24px,24px)
  position: relative

  &:before, &:after, span
    pseudo()
    width: 18px
    height: 3px
    border-radius: 1px
    background-color: #0B5180
    left: 3px
    top: 0
    transit()

  &:before
    top: 5px

  span
    top: 10px

  &:after
    top: 15px

  &.is-active
    span
      opacity: 0

    &:before, &:after
      top: 50%
      margin-top: -2px
      height: 4px

    &:before
      transform: rotate(45deg)

    &:after
      transform: rotate(-45deg)
