//- COLORS
$colorText = #000
$colorAccent = #00A0E3
$colorHover = #1ABBFF
$colorActive = #00A0E3


//- FONT
$font = -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif
$fontCreata = 'Creata', $font


//- VARS
$container = 1316px + (15 * 2)
$containerPadding = 15px
$gridPadding = (28px/2)


//- BREAKPOINTS
note = 'all and (max-width: 1759px)'
laptop = 'all and (max-width: 1399px)'
tablet = 'all and (max-width: 1279px)'
pad = 'all and (max-width: 1023px)'
phone = 'all and (max-width: 767px)'


/*!
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old = 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  note = 'all and (max-width: 1759px)'
  laptop = 'all and (max-width: 1399px)'
  tablet = 'all and (max-width: 1279px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/


/*!
  FONTS
  ===============================================
*/
@font-face
    font-family: 'Creata'
    src: local('Creata Bold'), local('Creata-Bold'),
        url('../fonts/hinted-Creata-Bold.woff2') format('woff2'),
        url('../fonts/hinted-Creata-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Creata'
    src: local('Creata Medium'), local('Creata-Medium'),
        url('../fonts/hinted-Creata-Medium.woff2') format('woff2'),
        url('../fonts/hinted-Creata-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Creata'
    src: local('Creata Bold Italic'), local('Creata-BoldItalic'),
        url('../fonts/hinted-Creata-BoldItalic.woff2') format('woff2'),
        url('../fonts/hinted-Creata-BoldItalic.woff') format('woff')
    font-weight: bold
    font-style: italic
    font-display: swap

@font-face
    font-family: 'Creata'
    src: local('Creata'), local('Creata-Regular'),
        url('../fonts/hinted-Creata-Regular.woff2') format('woff2'),
        url('../fonts/hinted-Creata-Regular.woff') format('woff')
    font-weight: normal
    font-style: normal
    font-display: swap

@font-face
    font-family: 'Creata'
    src: local('Creata Italic'), local('Creata-Italic'),
        url('../fonts/hinted-Creata-Italic.woff2') format('woff2'),
        url('../fonts/hinted-Creata-Italic.woff') format('woff')
    font-weight: normal
    font-style: italic
    font-display: swap
