/*
  BREAKPOINTS

  iPad Pro - 1366, 1024
  macbook old = 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  note = 'all and (max-width: 1759px)'
  laptop = 'all and (max-width: 1399px)'
  tablet = 'all and (max-width: 1279px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/
/*
  FONTS
  ===============================================
*/
@font-face {
  font-family: 'Creata';
  src: local('Creata Bold'), local('Creata-Bold'), url("../fonts/hinted-Creata-Bold.woff2") format('woff2'), url("../fonts/hinted-Creata-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Creata';
  src: local('Creata Medium'), local('Creata-Medium'), url("../fonts/hinted-Creata-Medium.woff2") format('woff2'), url("../fonts/hinted-Creata-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Creata';
  src: local('Creata Bold Italic'), local('Creata-BoldItalic'), url("../fonts/hinted-Creata-BoldItalic.woff2") format('woff2'), url("../fonts/hinted-Creata-BoldItalic.woff") format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Creata';
  src: local('Creata'), local('Creata-Regular'), url("../fonts/hinted-Creata-Regular.woff2") format('woff2'), url("../fonts/hinted-Creata-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Creata';
  src: local('Creata Italic'), local('Creata-Italic'), url("../fonts/hinted-Creata-Italic.woff2") format('woff2'), url("../fonts/hinted-Creata-Italic.woff") format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
/*
  BASE
  ===============================================
  */
body,
html {
  border: 0;
  width: 100%;
  overflow-x: hidden;
}
html {
  font-size: 16px;
}
html.is-no-scroll,
html.is-unimodal-active {
  overflow: hidden;
}
body {
  font-weight: normal;
  background-color: #fff;
  line-height: normal;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  scroll-behavior: auto;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
/*
  FOOTER TO BOTTOM
  ===============================================
  */
.wrapper {
  flex: 1 1 auto;
  width: 100%;
  min-height: 1vh;
  display: flex;
  flex-flow: column nowrap;
}
.main {
  flex: 1 1 auto;
  min-height: 1vh;
}
.header,
.footer {
  flex-shrink: 0;
}
/*
  UI ELEMENTS
  ===============================================
  */
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.32s linear;
}
strong,
b {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  width: auto;
  outline: none;
  line-height: 1;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.32s linear;
}
button,
button[type="submit"],
input[type="submit"] {
  cursor: pointer;
}
input[type="search"],
input[type="number"] {
  -webkit-appearance: textfield;
}
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button:disabled,
input:disabled,
textarea:disabled,
button.is-disabled,
input.is-disabled,
textarea.is-disabled {
  cursor: not-allowed;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
select.select,
.select select {
  appearance: none;
}
select.select::-ms-expand,
.select select::-ms-expand {
  display: none;
}
/*
  GRID
  ===============================================
  */
.rw {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -14px;
}
.cl {
  width: 100%;
  padding: 0 14px;
}
.cl-1 {
  max-width: 4.166666%;
}
.cl-2 {
  max-width: 8.333333%;
}
.cl-3 {
  max-width: 12.5%;
}
.cl-4 {
  max-width: 16.666666%;
}
.cl-5 {
  max-width: 20.833333%;
}
.cl-6 {
  max-width: 25%;
}
.cl-7 {
  max-width: 29.166666%;
}
.cl-8 {
  max-width: 33.333333%;
}
.cl-9 {
  max-width: 37.5%;
}
.cl-10 {
  max-width: 41.666666%;
}
.cl-11 {
  max-width: 45.833333%;
}
.cl-12 {
  max-width: 50%;
}
.cl-13 {
  max-width: 54.166666%;
}
.cl-14 {
  max-width: 58.333333%;
}
.cl-15 {
  max-width: 62.5%;
}
.cl-16 {
  max-width: 66.666666%;
}
.cl-17 {
  max-width: 70.833333%;
}
.cl-18 {
  max-width: 75%;
}
.cl-19 {
  max-width: 79.166666%;
}
.cl-20 {
  max-width: 83.333333%;
}
.cl-21 {
  max-width: 87.5%;
}
.cl-22 {
  max-width: 91.666666%;
}
.cl-23 {
  max-width: 95.833333%;
}
.cl-24 {
  max-width: 100%;
}
@media all and (max-width: 1759px) {
  .cl-nt-1 {
    max-width: 4.166666%;
  }
  .cl-nt-2 {
    max-width: 8.333333%;
  }
  .cl-nt-3 {
    max-width: 12.5%;
  }
  .cl-nt-4 {
    max-width: 16.666666%;
  }
  .cl-nt-5 {
    max-width: 20.833333%;
  }
  .cl-nt-6 {
    max-width: 25%;
  }
  .cl-nt-7 {
    max-width: 29.166666%;
  }
  .cl-nt-8 {
    max-width: 33.333333%;
  }
  .cl-nt-9 {
    max-width: 37.5%;
  }
  .cl-nt-10 {
    max-width: 41.666666%;
  }
  .cl-nt-11 {
    max-width: 45.833333%;
  }
  .cl-nt-12 {
    max-width: 50%;
  }
  .cl-nt-13 {
    max-width: 54.166666%;
  }
  .cl-nt-14 {
    max-width: 58.333333%;
  }
  .cl-nt-15 {
    max-width: 62.5%;
  }
  .cl-nt-16 {
    max-width: 66.666666%;
  }
  .cl-nt-17 {
    max-width: 70.833333%;
  }
  .cl-nt-18 {
    max-width: 75%;
  }
  .cl-nt-19 {
    max-width: 79.166666%;
  }
  .cl-nt-20 {
    max-width: 83.333333%;
  }
  .cl-nt-21 {
    max-width: 87.5%;
  }
  .cl-nt-22 {
    max-width: 91.666666%;
  }
  .cl-nt-23 {
    max-width: 95.833333%;
  }
  .cl-nt-24 {
    max-width: 100%;
  }
}
@media all and (max-width: 1399px) {
  .cl-lp-1 {
    max-width: 4.166666%;
  }
  .cl-lp-2 {
    max-width: 8.333333%;
  }
  .cl-lp-3 {
    max-width: 12.5%;
  }
  .cl-lp-4 {
    max-width: 16.666666%;
  }
  .cl-lp-5 {
    max-width: 20.833333%;
  }
  .cl-lp-6 {
    max-width: 25%;
  }
  .cl-lp-7 {
    max-width: 29.166666%;
  }
  .cl-lp-8 {
    max-width: 33.333333%;
  }
  .cl-lp-9 {
    max-width: 37.5%;
  }
  .cl-lp-10 {
    max-width: 41.666666%;
  }
  .cl-lp-11 {
    max-width: 45.833333%;
  }
  .cl-lp-12 {
    max-width: 50%;
  }
  .cl-lp-13 {
    max-width: 54.166666%;
  }
  .cl-lp-14 {
    max-width: 58.333333%;
  }
  .cl-lp-15 {
    max-width: 62.5%;
  }
  .cl-lp-16 {
    max-width: 66.666666%;
  }
  .cl-lp-17 {
    max-width: 70.833333%;
  }
  .cl-lp-18 {
    max-width: 75%;
  }
  .cl-lp-19 {
    max-width: 79.166666%;
  }
  .cl-lp-20 {
    max-width: 83.333333%;
  }
  .cl-lp-21 {
    max-width: 87.5%;
  }
  .cl-lp-22 {
    max-width: 91.666666%;
  }
  .cl-lp-23 {
    max-width: 95.833333%;
  }
  .cl-lp-24 {
    max-width: 100%;
  }
}
@media all and (max-width: 1279px) {
  .cl-tb-1 {
    max-width: 4.166666%;
  }
  .cl-tb-2 {
    max-width: 8.333333%;
  }
  .cl-tb-3 {
    max-width: 12.5%;
  }
  .cl-tb-4 {
    max-width: 16.666666%;
  }
  .cl-tb-5 {
    max-width: 20.833333%;
  }
  .cl-tb-6 {
    max-width: 25%;
  }
  .cl-tb-7 {
    max-width: 29.166666%;
  }
  .cl-tb-8 {
    max-width: 33.333333%;
  }
  .cl-tb-9 {
    max-width: 37.5%;
  }
  .cl-tb-10 {
    max-width: 41.666666%;
  }
  .cl-tb-11 {
    max-width: 45.833333%;
  }
  .cl-tb-12 {
    max-width: 50%;
  }
  .cl-tb-13 {
    max-width: 54.166666%;
  }
  .cl-tb-14 {
    max-width: 58.333333%;
  }
  .cl-tb-15 {
    max-width: 62.5%;
  }
  .cl-tb-16 {
    max-width: 66.666666%;
  }
  .cl-tb-17 {
    max-width: 70.833333%;
  }
  .cl-tb-18 {
    max-width: 75%;
  }
  .cl-tb-19 {
    max-width: 79.166666%;
  }
  .cl-tb-20 {
    max-width: 83.333333%;
  }
  .cl-tb-21 {
    max-width: 87.5%;
  }
  .cl-tb-22 {
    max-width: 91.666666%;
  }
  .cl-tb-23 {
    max-width: 95.833333%;
  }
  .cl-tb-24 {
    max-width: 100%;
  }
}
@media all and (max-width: 1023px) {
  .cl-pd-1 {
    max-width: 4.166666%;
  }
  .cl-pd-2 {
    max-width: 8.333333%;
  }
  .cl-pd-3 {
    max-width: 12.5%;
  }
  .cl-pd-4 {
    max-width: 16.666666%;
  }
  .cl-pd-5 {
    max-width: 20.833333%;
  }
  .cl-pd-6 {
    max-width: 25%;
  }
  .cl-pd-7 {
    max-width: 29.166666%;
  }
  .cl-pd-8 {
    max-width: 33.333333%;
  }
  .cl-pd-9 {
    max-width: 37.5%;
  }
  .cl-pd-10 {
    max-width: 41.666666%;
  }
  .cl-pd-11 {
    max-width: 45.833333%;
  }
  .cl-pd-12 {
    max-width: 50%;
  }
  .cl-pd-13 {
    max-width: 54.166666%;
  }
  .cl-pd-14 {
    max-width: 58.333333%;
  }
  .cl-pd-15 {
    max-width: 62.5%;
  }
  .cl-pd-16 {
    max-width: 66.666666%;
  }
  .cl-pd-17 {
    max-width: 70.833333%;
  }
  .cl-pd-18 {
    max-width: 75%;
  }
  .cl-pd-19 {
    max-width: 79.166666%;
  }
  .cl-pd-20 {
    max-width: 83.333333%;
  }
  .cl-pd-21 {
    max-width: 87.5%;
  }
  .cl-pd-22 {
    max-width: 91.666666%;
  }
  .cl-pd-23 {
    max-width: 95.833333%;
  }
  .cl-pd-24 {
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .cl-ph-1 {
    max-width: 4.166666%;
  }
  .cl-ph-2 {
    max-width: 8.333333%;
  }
  .cl-ph-3 {
    max-width: 12.5%;
  }
  .cl-ph-4 {
    max-width: 16.666666%;
  }
  .cl-ph-5 {
    max-width: 20.833333%;
  }
  .cl-ph-6 {
    max-width: 25%;
  }
  .cl-ph-7 {
    max-width: 29.166666%;
  }
  .cl-ph-8 {
    max-width: 33.333333%;
  }
  .cl-ph-9 {
    max-width: 37.5%;
  }
  .cl-ph-10 {
    max-width: 41.666666%;
  }
  .cl-ph-11 {
    max-width: 45.833333%;
  }
  .cl-ph-12 {
    max-width: 50%;
  }
  .cl-ph-13 {
    max-width: 54.166666%;
  }
  .cl-ph-14 {
    max-width: 58.333333%;
  }
  .cl-ph-15 {
    max-width: 62.5%;
  }
  .cl-ph-16 {
    max-width: 66.666666%;
  }
  .cl-ph-17 {
    max-width: 70.833333%;
  }
  .cl-ph-18 {
    max-width: 75%;
  }
  .cl-ph-19 {
    max-width: 79.166666%;
  }
  .cl-ph-20 {
    max-width: 83.333333%;
  }
  .cl-ph-21 {
    max-width: 87.5%;
  }
  .cl-ph-22 {
    max-width: 91.666666%;
  }
  .cl-ph-23 {
    max-width: 95.833333%;
  }
  .cl-ph-24 {
    max-width: 100%;
  }
}
/*
  UI
  ===============================================
  */
body {
  font-family: 'Creata', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #000;
}
.container {
  width: 100%;
  max-width: 1346px;
  padding: 0 15px;
  margin: 0 auto;
}
.link {
  transition: all 0.32s linear;
}
.link:hover {
  opacity: 0.6;
}
.input {
  display: block;
  width: 100%;
}
.hide {
  display: none;
}
.img {
  display: block;
  max-width: 100%;
}
picture {
  display: block;
}
picture img {
  display: block;
  width: 100%;
}
.picture-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.picture-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tab {
  display: none;
  visibility: hidden;
  opacity: 0;
}
.tab.is-active {
  display: block;
  visibility: visible;
  animation: tab-show 1s linear 0.01s 1 forwards;
}
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}
.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.icon,
.ico {
  display: inline-block;
}
/*
  TEXT
  ===============================================
*/
.ui-h1 {
  font-size: 48px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.08;
}
@media all and (max-width: 1023px) {
  .ui-h1 {
    font-size: 38px;
  }
}
@media all and (max-width: 767px) {
  .ui-h1 {
    font-size: 32px;
  }
}
.ui-h2 {
  font-size: 32px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.12;
}
.ui-h3 {
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;
}
.ui-h4 {
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.25;
}
.ui-txt {
  font-size: 20px;
  line-height: 1.3;
  font-style: normal;
  font-weight: normal;
}
.ui-txt-small {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 1.12;
}
.ui-content p:not(:last-child) {
  margin-bottom: 12px;
}
.ui-link:hover {
  color: #1abbff;
}
.ui-link:active {
  color: #00a0e3;
}
/*
  BUTTON
  ===============================================
*/
.ui-btn {
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #00a0e3;
  padding: 0 12px;
}
.ui-btn:hover {
  background-color: #1abbff;
}
.ui-btn--red {
  background-color: #f53d3d;
}
.ui-btn--red:hover {
  background-color: #f66;
}
/*
  ELEMENTS
  ===============================================
*/
.ui-page {
  padding: 44px 0 0;
}
.page-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 60px;
}
@media all and (max-width: 1023px) {
  .page-title {
    margin-bottom: 44px;
  }
}
@media all and (max-width: 1023px) {
  .page-layout {
    flex-direction: column-reverse;
  }
}
.page-layout__left {
  padding-left: 40px;
}
.cat-sidebar {
  padding-top: 20px;
}
@media all and (max-width: 1023px) {
  .cat-sidebar {
    display: flex;
    justify-content: space-between;
  }
}
@media all and (max-width: 767px) {
  .cat-sidebar {
    flex-direction: column;
  }
}
.cat-sidebar:not(:last-child) {
  margin-bottom: 52px;
}
.cat-sidebar__title {
  text-transform: uppercase;
  margin-bottom: 14px;
}
.cat-sidebar__title span {
  display: inline-block;
  padding-bottom: 1em;
  border-bottom: 2px solid #0b5180;
}
.cat-sidebar__item:not(:last-child) {
  margin-bottom: 52px;
}
.cat-list {
  color: #0b5180;
}
.cat-list__item {
  position: relative;
}
.cat-list__item:before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 70%;
  background-size: 100% auto;
  background-image: url("../icons/chevron-nav.svg");
  right: 100%;
  margin-right: 10px;
  display: none;
}
.cat-list__item.is-active {
  color: #000;
}
.cat-list__item.is-active:before {
  display: block;
}
.cat-list__item:not(:last-child) {
  margin-bottom: 16px;
}
.cat-list__link:hover {
  color: #1abbff;
}
.cat-list__link:active {
  color: #00a0e3;
}
.cat-list__sub[class] {
  margin-top: 18px;
  margin-left: 28px;
}
.b-callback__icon {
  width: 90px;
  height: 76px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("../icons/dialog.svg");
  margin-bottom: 16px;
  margin-left: -10px;
}
.b-callback__title {
  text-transform: uppercase;
  margin-bottom: 24px;
}
.b-callback__btn {
  margin-top: 14px;
}
.b-grid__item {
  padding-bottom: 12px;
}
.card-product {
  display: block;
  padding: 28px;
}
.card-product__picture {
  max-width: 252px;
  max-height: 252px;
  margin: 0 auto 24px;
}
@media all and (max-width: 767px) {
  .card-product__picture {
    height: 200px;
  }
}
.card-product__picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-product__title {
  text-align: center;
  text-transform: uppercase;
}
@-moz-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
  HEADER
  ===============================================
  */
.header {
  position: relative;
  box-shadow: 0px 4px 4px rgba(11,81,128,0.1);
  padding: 12px 0;
}
@media all and (max-width: 1023px) {
  .header {
    height: 40px;
    z-index: 10;
  }
}
.header .h-logo {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 1279px) {
  .header .h-logo {
    max-width: 100px;
  }
}
.header .burger {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  left: 20px;
  display: none;
}
@media all and (max-width: 1023px) {
  .header .burger {
    display: block;
  }
}
.header.is-active .h-box {
  display: block;
  visibility: visible;
  animation: tab-show 0.25s linear 0.01s 1 forwards;
}
.h-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 1023px) {
  .h-box {
    position: fixed;
    top: 40px;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    z-index: 5;
    display: block;
    padding-top: 48px;
    box-shadow: inset 0px 4px 4px rgba(11,81,128,0.1);
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
@media all and (max-width: 1023px) {
  .h-box__left {
    margin-bottom: 72px;
  }
}
.h-logo {
  display: block;
  width: 100%;
  max-width: 150px;
}
@media all and (max-width: 1279px) {
  .h-logo {
    max-width: 100px;
  }
}
.h-menu {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
@media all and (max-width: 1023px) {
  .h-menu {
    flex-direction: column;
    width: 100%;
    font-size: 20px;
    font-weight: normal;
    text-transform: none;
  }
}
.h-menu__item:not(:last-child) {
  margin-right: 8px;
}
@media all and (max-width: 1279px) {
  .h-menu__item:not(:last-child) {
    margin-right: 0;
  }
}
@media all and (max-width: 1023px) {
  .h-menu__item:not(:last-child) {
    margin-bottom: 16px;
  }
}
.h-menu__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  padding: 0 12px;
}
.h-menu__link:hover {
  color: #1abbff;
}
.h-menu__link:active {
  color: #00a0e3;
}
.h-menu__link.is-current {
  color: #fff;
  background-color: #00a0e3;
}
@media all and (max-width: 1023px) {
  .h-menu__link.is-current {
    background: none;
    color: #00a0e3;
  }
}
.h-contact {
  display: inline-flex;
  align-items: center;
}
@media all and (max-width: 1023px) {
  .h-contact {
    display: block;
    text-align: center;
  }
}
.h-contact__phone {
  font-size: 14px;
  color: #000;
  font-weight: 500;
  padding: 0 20px;
}
@media all and (max-width: 1023px) {
  .h-contact__phone {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 12px;
  }
}
@media all and (max-width: 1023px) {
  .h-contact__btn .ui-btn {
    background-color: #00a0e3;
  }
}
.burger {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
}
.burger:before,
.burger:after,
.burger span {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 3px;
  border-radius: 1px;
  background-color: #0b5180;
  left: 3px;
  top: 0;
  transition: all 0.32s linear;
}
.burger:before {
  top: 5px;
}
.burger span {
  top: 10px;
}
.burger:after {
  top: 15px;
}
.burger.is-active span {
  opacity: 0;
}
.burger.is-active:before,
.burger.is-active:after {
  top: 50%;
  margin-top: -2px;
  height: 4px;
}
.burger.is-active:before {
  transform: rotate(45deg);
}
.burger.is-active:after {
  transform: rotate(-45deg);
}
/*
  FOOTER
  ===============================================
  */
.footer {
  height: 80px;
  display: flex;
  align-items: center;
  color: rgba(0,0,0,0.2);
  text-align: center;
}
/*
  PAGE-DEFAULT
  ===============================================
*/
.page-default {
  padding-top: 75px;
}
@media all and (max-width: 1023px) {
  .page-default {
    padding-top: 44px;
  }
}
