//- TEXT TRUNCATE
trunc($lines)
  display: box
  display: -ms-box
  display: -webkit-box
  display: -moz-box
  text-overflow: ellipsis
  overflow: hidden
  -webkit-line-clamp: $lines
  box-orient: vertical
  -moz-box-orient: vertical
  -webkit-box-orient: vertical
  -ms-box-orient: vertical


//- TRANSITION
transit($duration = 0.32s)
  transition: all $duration linear


//- PSEUDO ELEMENTS
pseudo()
  content: ''
  display: block
  position: absolute


//- BACKGROUND
bg($position, $size)
  background-repeat: no-repeat
  background-position: $position
  background-size: $size


//- SET SIZE
size($wsize, $hsize)
  width: $wsize
  height: $hsize


//- GRID
cl($num, $columns)
  max-width: floor( ((100% / $columns) * $num), 2 )


col($num, $columns)
  width: 100%
  max-width: floor( ((100% / $columns) * $num), 2 )


grid($columns, $prefix = '')
  for $col in 1..$columns
    .cl{$prefix}-{$col}
      max-width floor( ((100% / $columns) * $col), 6 )
