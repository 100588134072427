/*!
  FOOTER
  ===============================================
  */
.footer
  height: 80px
  display: flex
  align-items: center
  color: rgba(#000,0.2)
  text-align: center
